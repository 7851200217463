// src/components/VehicleDetails.js
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { firestore } from "../firebase";
import { collection, query, getDocs, updateDoc, deleteDoc, doc, orderBy, getDoc } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

export default function UserDetails() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [logbookRecords, setLogbookRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null); // Store selected record for editing
  const [odometerStart, setOdometerStart] = useState('');
  const [odometerEnd, setOdometerEnd] = useState('');
  const [purpose, setPurpose] = useState('');
  const [distance, setDistance] = useState(0);
  const [totalMileageOverride, setTotalMileageOverride] = useState(''); // State for overriding total mileage
  const [open, setOpen] = useState(false); // State to control dialog

  useEffect(() => {
    fetchLogbookRecords(userId);
  }, [userId]);

  const fetchLogbookRecords = async (userId) => {
    setLoading(true);
    try {
      const q = query(
        collection(firestore, "records", userId, "mileage"),
        orderBy("detailDate", "desc")
      );
      const querySnapshot = await getDocs(q);

      const docsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLogbookRecords(docsArray);
    } catch (error) {
      console.error("Error fetching user records:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClick = (record) => {
    setSelectedRecord(record);
    setOdometerStart(record.odometerStart || '');
    setOdometerEnd(record.odometerEnd || '');
    setPurpose(record.purpose || '');
    setTotalMileageOverride(''); // Reset the total mileage override when editing a new record
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRecord(null);
    setOdometerStart('');
    setOdometerEnd('');
    setPurpose('');
    setDistance(0);
    setTotalMileageOverride(''); // Reset total mileage override on close
  };

  const handleSave = async () => {
    if (selectedRecord) {
      const currentMileageDiff = selectedRecord.odometerEnd - selectedRecord.odometerStart;
      const updatedData = {
        odometerStart: Number(odometerStart),
        odometerEnd: Number(odometerEnd),
        distance: Number(distance),
        purpose: purpose, 
      };
      await updateDoc(doc(firestore, "records", userId, "mileage", selectedRecord.id), updatedData);

      const userDocRef = doc(firestore, "records", userId);
      const userDocSnap = await getDoc(userDocRef);
      let currentTotalMileage = 0;
      if (userDocSnap.exists()) {
        currentTotalMileage = userDocSnap.data().totalMileage || 0;
      }
      const newMileageDiff = Number(odometerEnd) - Number(odometerStart);
      const newTotalMileage = totalMileageOverride
        ? Number(totalMileageOverride) // Use override value if provided
        : currentTotalMileage - currentMileageDiff + newMileageDiff;

      await updateDoc(userDocRef, {
        totalMileage: Number(newTotalMileage),
      });

      fetchLogbookRecords(userId);
      handleClose();
    }
  };

  useEffect(() => {
    if (odometerStart !== '' && odometerEnd !== '') {
      setDistance(Number(odometerEnd) - Number(odometerStart));
    }
  }, [odometerStart, odometerEnd]);

  const handleDeleteClick = async (logbookId) => {
    try {
      // Fetch the mileage record to get odometer values
      const logbookRef = doc(firestore, "records", userId, "mileage", logbookId);
      const logbookSnap = await getDoc(logbookRef);
  
      if (logbookSnap.exists()) {
        const { odometerStart, odometerEnd } = logbookSnap.data();
        const mileageDiff = odometerEnd - odometerStart;
  
        // Fetch user's total mileage
        const userDocRef = doc(firestore, "records", userId);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          let currentTotalMileage = userDocSnap.data().totalMileage || 0;
  
          // Update total mileage by subtracting the mileageDiff
          const newTotalMileage = currentTotalMileage - mileageDiff;
  
          // Update the total mileage in Firestore
          await updateDoc(userDocRef, {
            totalMileage: newTotalMileage,
          });
        }
  
        // Delete the mileage record
        await deleteDoc(logbookRef);
  
        // Refresh the logbook records
        fetchLogbookRecords(userId);
      } else {
        console.error("Mileage record not found.");
      }
    } catch (error) {
      console.error("Error deleting logbook record:", error);
    }
  };  

  const columns = [
    { field: 'rank', headerName: 'Rank', width: 70 },
    { field: 'name', headerName: 'Name', width: 150 },
    { 
      field: 'detailDate', 
      headerName: 'Date', 
      width: 120,
      valueGetter: (detailDate) => {
        if (detailDate) {
          const date = new Date(detailDate);
          return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
        }
        return ''; // Return an empty string if detailDate is invalid
      }  
    },
    { field: 'timeStart', headerName: 'Start Time', width: 100 },
    { field: 'timeEnd', headerName: 'End Time', width: 100 },
    { field: 'vehMid', headerName: 'Vehicle MID', width: 100 },
    { field: 'odometerStart', headerName: 'Odometer Start', width: 150 },
    { field: 'odometerEnd', headerName: 'Odometer End', width: 150 },
    { field: 'distance', headerName: 'Distance', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateClick(params.row)}
            sx={{ marginRight: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Mileage - {userId}
      </Typography>

      <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
        <DataGrid
          rows={logbookRecords}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={loading}
          getRowId={(row) => row.id}
          pinnedColumns={{right: ['actions']}}
        />
      </Box>

      {/* Edit Mileage Record Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Mileage Record</DialogTitle>
        <DialogContent>
          <TextField
            label="Odometer Start"
            type="number"
            fullWidth
            margin="normal"
            value={odometerStart}
            onChange={(e) => setOdometerStart(e.target.value)}
          />
          <TextField
            label="Odometer End"
            type="number"
            fullWidth
            margin="normal"
            value={odometerEnd}
            onChange={(e) => setOdometerEnd(e.target.value)}
          />
          <TextField
            label="Distance"
            type="number"
            fullWidth
            margin="normal"
            value={distance}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Purpose"
            type="text"
            fullWidth
            margin="normal"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />
          <TextField
            label="Override Total Mileage"
            type="number"
            fullWidth
            margin="normal"
            value={totalMileageOverride}
            onChange={(e) => setTotalMileageOverride(e.target.value)}
            helperText="Leave empty to auto-calculate based on mileage differences"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={() => navigate(-1)}>
        Back to Users
      </Button>
    </Box>
  );
}
