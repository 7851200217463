// src/components/Sidebar.js
import { useMediaQuery, List, ListItem, ListItemText, Drawer, Typography, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Sidebar({ mobileOpen, handleDrawerToggle, logout }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        width: 180,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 180,
          boxSizing: 'border-box',
          backgroundColor: '#1e1e1e',
          color: '#ffffff',
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Typography variant="h6" sx={{ textAlign: 'center', padding: '16px 0', color: '#ffffff' }}>
        Wallaby Tracker Editor
      </Typography>
      <Divider sx={{ backgroundColor: '#555555' }} />
      <List>
        <ListItem
          button
          component={Link}
          to="/users"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null} // Close drawer on mobile after clicking an item
        >
          <ListItemText primary="Users" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/vehicles"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Vehicles" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/records"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Records" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/statistics"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Statistics" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
        <ListItem
          button
          component={Link}
          to="/update-passcode"
          sx={{
            textDecoration: 'none',
            '&:hover': { backgroundColor: '#333333' },
            '&:active': { color: '#ffffff' },
          }}
          onClick={isMobile ? handleDrawerToggle : null}
        >
          <ListItemText primary="Update Passcode" sx={{ color: '#ffffff' }} />
        </ListItem>
        <Divider component="li" sx={{ backgroundColor: '#555555' }} />
      </List>

      {/* Smaller, Rounded Logout Button */}
      <List sx={{ marginTop: 'auto', paddingBottom: 2 }}>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={logout}
            sx={{
              backgroundColor: '#e57373',
              color: '#ffffff',
              borderRadius: '20px', // Rounded corners
              padding: '6px 12px', // Smaller padding
              fontSize: '0.875rem', // Small font size
              '&:hover': {
                backgroundColor: '#ef5350',
              },
            }}
            variant="contained"
          >
            Logout
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
}

