import { useState } from 'react';
import { TextField, Button, Typography, Paper, Box } from '@mui/material';

function PasscodeUpdate({ onPasscodeUpdate }) {
  const [newPasscode, setNewPasscode] = useState('');

  // Handle passcode update submission
  const handleUpdatePasscode = async () => {
    if (newPasscode.trim()) {
      const response = await fetch('/api/update-passcode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPasscode }),
      });

      if (response.ok) {
        alert('Passcode updated successfully!');
        onPasscodeUpdate(); // Callback to notify parent component of the update
        setNewPasscode(''); // Reset field after successful update
      } else {
        alert('Failed to update passcode!');
      }
    } else {
      alert('Failed to update passcode.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e0e0e0',
        padding: 2,
        height: '100vh',
      }}
    >
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          maxWidth: 400,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
          Admin: Update Passcode
        </Typography>
        <TextField
          label="New Passcode"
          variant="outlined"
          value={newPasscode}
          onChange={(e) => setNewPasscode(e.target.value)}
          fullWidth
          sx={{ marginBottom: 3 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            padding: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s',
            ':hover': {
              backgroundColor: '#0056b3',
            },
          }}
          onClick={handleUpdatePasscode}
        >
          Update Passcode
        </Button>
      </Paper>
    </Box>
  );
}

export default PasscodeUpdate;
