// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDFT-v8f2L28ARHsn7DP235E-f78KugvCI",
    authDomain: "wallaby-tracker.firebaseapp.com",
    projectId: "wallaby-tracker",
    storageBucket: "wallaby-tracker.appspot.com",
    messagingSenderId: "457108489549",
    appId: "1:457108489549:web:85f9b1284b57b45d932304",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };
