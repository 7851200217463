// src/components/VehicleDetails.js
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { firestore } from "../firebase";
import { collection, query, getDocs, updateDoc, deleteDoc, doc, orderBy } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

export default function VehicleDetails() {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const [logbookRecords, setLogbookRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null); // Store selected record for editing
  const [odometerStart, setOdometerStart] = useState('');
  const [odometerEnd, setOdometerEnd] = useState('');
  const [purpose, setPurpose] = useState('');
  const [distance, setDistance] = useState(0);
  const [open, setOpen] = useState(false); // State to control dialog

  useEffect(() => {
    fetchLogbookRecords(vehicleId);
  }, [vehicleId]);

  const fetchLogbookRecords = async (vehicleMID) => {
    setLoading(true);
    try {
      const q = query(
        collection(firestore, "logbook", vehicleMID, "mileage"),
        orderBy("detailDate", "desc")
      );
      const querySnapshot = await getDocs(q);

      const docsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLogbookRecords(docsArray);
    } catch (error) {
      console.error("Error fetching logbook records:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClick = (record) => {
    setSelectedRecord(record);
    setOdometerStart(record.odometerStart || '');
    setOdometerEnd(record.odometerEnd || '');
    setPurpose(record.purpose || '');
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRecord(null);
    setOdometerStart('');
    setOdometerEnd('');
    setPurpose(''); 
    setDistance(0);
  };

  const handleSave = async () => {
    if (selectedRecord) {
      const updatedData = {
        odometerStart: odometerStart,
        odometerEnd: odometerEnd,
        distance: Number(distance),
        purpose: purpose, 
      };
      await updateDoc(doc(firestore, "logbook", vehicleId, "mileage", selectedRecord.id), updatedData);
      await updateDoc(doc(firestore, "vehicles", vehicleId), {
        odometerStart: String(odometerEnd),
      });
      fetchLogbookRecords(vehicleId);
      handleClose();
    }
  };

  useEffect(() => {
    if (odometerStart !== '' && odometerEnd !== '') {
      setDistance(Number(odometerEnd) - Number(odometerStart));
    }
  }, [odometerStart, odometerEnd]);

  const handleDeleteClick = async (logbookId) => {
    await deleteDoc(doc(firestore, "logbook", vehicleId, "mileage", logbookId));
    const q = query(
      collection(firestore, "logbook", vehicleId, "mileage"),
      orderBy("detailDate", "desc")
    );
    const querySnapshot = await getDocs(q);
    const updatedRecords = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLogbookRecords(updatedRecords);
    if (updatedRecords.length >= 1) {
      const secondLatestOdometerEnd = updatedRecords[0].odometerEnd; // second latest record's odometerEnd
      await updateDoc(doc(firestore, "vehicles", vehicleId), {
        odometerStart: String(secondLatestOdometerEnd),
      });
    } else if (updatedRecords.length === 1) {
      // If only one record left, update to 0
      await updateDoc(doc(firestore, "vehicles", vehicleId), {
        odometerStart: String(0),
      });
    }
    fetchLogbookRecords(vehicleId);
  };

  const columns = [
    { field: 'rank', headerName: 'Rank', width: 70 },
    { field: 'name', headerName: 'Name', width: 150 },
    { 
      field: 'detailDate', 
      headerName: 'Date', 
      width: 120,
      valueGetter: (detailDate) => {
        if (detailDate) {
          const date = new Date(detailDate);
          return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
        }
        return ''; // Return an empty string if detailDate is invalid
      }  
    },
    { field: 'timeStart', headerName: 'Start Time', width: 100 },
    { field: 'timeEnd', headerName: 'End Time', width: 100 },
    { field: 'odometerStart', headerName: 'Odometer Start', width: 150 },
    { field: 'odometerEnd', headerName: 'Odometer End', width: 150 },
    { field: 'distance', headerName: 'Distance', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateClick(params.row)}
            sx={{ marginRight: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];  

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Vehicle Logbook - {vehicleId}
      </Typography>

      <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
        <DataGrid
          rows={logbookRecords}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={loading}
          getRowId={(row) => row.id}
          pinnedColumns={{right: ['actions']}}
        />
      </Box>

      {/* Edit Wizard Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Logbook Record</DialogTitle>
        <DialogContent>
          <TextField
            label="Odometer Start"
            type="number"
            fullWidth
            margin="normal"
            value={odometerStart}
            onChange={(e) => setOdometerStart(e.target.value)}
          />
          <TextField
            label="Odometer End"
            type="number"
            fullWidth
            margin="normal"
            value={odometerEnd}
            onChange={(e) => setOdometerEnd(e.target.value)}
          />
          <TextField
            label="Purpose"
            type="text"
            fullWidth
            margin="normal"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />
          <TextField
            label="Distance"
            type="number"
            fullWidth
            margin="normal"
            value={distance}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={() => navigate(-1)}>
        Back to Vehicles
      </Button>
    </Box>
  );
}
