// src/App.js
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import VehicleDetails from './components/VehicleDetails';
import UserDetails from './components/UserDetails';
import HomePage from './components/HomePage';
import Statistics from './components/Statistics';
import Passcode from './components/Passcode';
import PasscodeUpdate from './components/PasscodeUpdate';
import { CssBaseline, Box, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import MenuIcon
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [passcodeValidated, setPasscodeValidated] = useState(false); // Track if passcode is correct
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen); // Toggle sidebar
  };

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen

  // Logout function
  const logout = () => {
    localStorage.removeItem('passcodeValidated');
    localStorage.removeItem('passcodeTimestamp');
    setPasscodeValidated(false);
  };

  useEffect(() => {
    const isPasscodeValidated = localStorage.getItem('passcodeValidated') === 'true';
    setPasscodeValidated(isPasscodeValidated);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
          {/* Display MenuIcon only on mobile and hide when sidebar is open */}
          {isMobile && !mobileOpen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle} // Toggle the drawer
              sx={{
                position: 'absolute',
                top: 16,
                left: 16,
                zIndex: 1300, // Ensure it appears above other components
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} logout={logout} />
          <Box
            sx={{
              flexGrow: 1,
              padding: isMobile ? 2 : 3,
              display: 'flex',
              flexDirection: 'column',
              width: '100%', // Ensure it spans the full width
              maxWidth: '100%',
              marginLeft: isMobile ? 0 : 0, // Ensure content doesn't overlap with sidebar on desktop
              marginTop: isMobile ? 4 : 0,
              overflowX: 'hidden', 
            }}
          >
            {!passcodeValidated ? (
              <Passcode onPasscodeSubmit={setPasscodeValidated} />
            ) : (
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/:collectionName" element={<Content />} />
                <Route path="/vehicles/:vehicleId" element={<VehicleDetails />} />
                <Route path="/records/:userId" element={<UserDetails />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/update-passcode" element={<PasscodeUpdate />} />
              </Routes>
            )}
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
