import { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import PasscodeUpdate from './PasscodeUpdate'; // Import new component

function Passcode({ onPasscodeSubmit, isLoggedIn }) {
  const [passcode, setPasscode] = useState('');
  const [correctPasscode, setCorrectPasscode] = useState(null);

  // Fetch passcode from Cloudflare KV
  useEffect(() => {
    const fetchPasscode = async () => {
      const response = await fetch('/api/get-passcode');
      const data = await response.json();
      setCorrectPasscode(data.passcode);
    };
    fetchPasscode();
  }, []);

  useEffect(() => {
    const validatePasscodeTime = () => {
      const storedTimestamp = localStorage.getItem('passcodeTimestamp');
      if (storedTimestamp) {
        const currentTime = new Date().getTime();
        const timeElapsed = currentTime - parseInt(storedTimestamp, 10);
        const oneHour = 60 * 60 * 1000;

        if (timeElapsed > oneHour) {
          localStorage.removeItem('passcodeValidated');
          localStorage.removeItem('passcodeTimestamp');
          onPasscodeSubmit(false); // Log out the user
        }
      }
    };

    // Check every minute if the user should be logged out
    const intervalId = setInterval(validatePasscodeTime, 60 * 1000);
    
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [onPasscodeSubmit]);

  // Handle passcode submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (passcode === correctPasscode) {
      onPasscodeSubmit(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('passcodeValidated', 'true');
      localStorage.setItem('passcodeTimestamp', currentTime);
    } else {
      alert('Incorrect passcode!');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 400,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 3 }}>
          Login to Wallaby Mileage Editor
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          What is the passcode to enter this protected page?
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Passcode"
            variant="outlined"
            type="password"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            fullWidth
            sx={{ marginBottom: 3 }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>

        {isLoggedIn && (
          <PasscodeUpdate
            isLoggedIn={isLoggedIn}
            onPasscodeUpdate={() => alert('Passcode has been updated')}
          />
        )}
      </Paper>
    </Box>
  );
}

export default Passcode;
