import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { firestore } from "../firebase";
import { collection, query, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { DataGrid } from '@mui/x-data-grid';
import { Box, LinearProgress, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Grid } from '@mui/material';
import debounce from 'lodash/debounce';

const auth = getAuth();

// Define special platforms and type of platforms
const specialPlatforms = {
  "LandCruiser SUV": false,
  MiniBus: false,
  "Twin Cab": false,
};

const typeOfPlatforms = {
  LandRover: false,
  "OUV (FordEverest)": false,
  "LUV (Agilis)": false,
  "MB240 RecceJeep": false,
  "MB240 SpikeJeep": false,
  MB290GS: false,
  "MB290 (GRS)": false,
  "MB290 (MPSTAR)": false,
  "F550 (Ambulance)": false,
  "F550 (ComdPost)": false,
  "LightStrike (MK II AGL)": false,
  "LightStrike (MK II VLSS)": false,
  "LightStrike (MK II UTILITY)": false,
  "3TON GEN (PSD)": false,
  "5TONGS": false,
  "5TON (BCS)": false,
  "5TON (BLS/BFI)": false,
  "5TON (PTG)": false,
  "5TON (T-HUB)": false,
  "5TON (CP)": false,
  "5TON (S/T)": false,
  "5TON (MTV CRANE)": false,
  "6TONGS": false,
  "6TON (BCS)": false,
  "6TON (PTG)": false,
  "6TON (FSD)": false,
  "DNCC (ETHAN)": false,
  HMCT: false,
  "HMCT (A1TaCC)": false,
  "HMCT (SHIKRA)": false,
  "HMCT (SPYDER)": false,
  "LARC V": false,
  "WheelRecoveryVehicle (WRV)": false,
  RD: false,
  VSD: false,
  V15: false,
  "UAV (Parrot & Css)": false,
};

// Helper function to fix the user account and return the fields added
async function fixAccountIfNotExist(userId) {
  const requiredDocs = [
    "lastDriven",
    "jit",
    "orientation",
    "platforms",
    "records",
    "specialPlatforms",
    "specialPlatformsTraining"
  ];

  const fieldsAdded = []; // Track added fields

  for (const docName of requiredDocs) {
    const docRef = doc(firestore, docName, userId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      switch (docName) {
        case "lastDriven":
        case "jit":
        case "orientation":
          await setDoc(docRef, {});
          break;
        case "platforms":
          await setDoc(docRef, typeOfPlatforms);
          break;
        case "specialPlatforms":
        case "specialPlatformsTraining":
          await setDoc(docRef, specialPlatforms);
          break;
        case "records":
          await setDoc(docRef, { totalMileage: 0 });
          break;
        default:
          console.error("Unknown document name", docName);
      }
      fieldsAdded.push(docName);
    }
  }

  return fieldsAdded;
}

function ProgressDialog({ open, progress, userName, isCompleted, handleClose, handleCancel }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Progress</DialogTitle>
      <DialogContent>
        {isCompleted ? (
          <Typography>Operation completed!</Typography>
        ) : (
          <>
            <Typography>{`Progress: ${progress}%`}</Typography>
            <LinearProgress variant="determinate" value={progress} />
            <Typography>{`${userName} re-calculation / de-duplication in progress.`}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!isCompleted && (
          <Button onClick={handleCancel} color="error">
            Cancel
          </Button>
        )}
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Content() {
  const { collectionName } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addedFields, setAddedFields] = useState([]);
  const [resetEmailStatus, setResetEmailStatus] = useState(null);
  const [recalculatedMileage, setRecalculatedMileage] = useState(null);
  const [removedDuplicatesCount, setRemovedDuplicatesCount] = useState(null); 
  const [progress, setProgress] = useState(0);
  const [progressOpen, setProgressOpen] = useState(false);
  const [currentUserName, setCurrentUserName] = useState("");
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [isDeduplicating, setIsDeduplicating] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);  
  const navigate = useNavigate();
  const isCancelledRef = useRef(false);
  const isMobile = useMediaQuery('(max-width:800px)');

  useEffect(() => {
    fetchDocuments();
  }, [collectionName]);

  useEffect(() => {
    const debouncedFetch = debounce(() => fetchDocuments(), 300);
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [searchQuery]);

  const handleCancelOperation = () => {
    isCancelledRef.current = true; 
    fetchDocuments();
    setProgress(0);
    setCurrentUserName("");
    setIsCompleted(false);
  };  

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const q = query(collection(firestore, collectionName));
      const querySnapshot = await getDocs(q);
      const docsArray = await Promise.all(querySnapshot.docs.map(async (docSnap) => {
        const data = docSnap.data();

        if (collectionName === "records" && docSnap.id) {
          const userRef = doc(firestore, "users", docSnap.id);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            data.userRank = userData.rank || 'N/A';
            data.userName = userData.name || 'N/A';
          } else {
            data.userRank = 'N/A';
            data.userName = 'N/A';
          }
        }
        return { id: docSnap.id, ...data };
      }));

      const filteredDocs = docsArray.filter(doc => {
        if (collectionName === 'vehicles') {
          return doc.id.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (collectionName === 'users') {
          return doc.name.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (collectionName === 'records') {
          return doc.userName.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true;
      });

      setDocuments(filteredDocs);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handler for "Fix Account" button click (only for 'users' collection)
  const handleFixAccountClick = async (userId) => {
    try {
      const fieldsAdded = await fixAccountIfNotExist(userId);
      setAddedFields(fieldsAdded);
      setDialogOpen(true);
      fetchDocuments();
    } catch (error) {
      console.error("Error fixing account:", error);
    }
  };

  const handleResetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailStatus({ success: true, message: `Password reset email sent to ${email}.` });
    } catch (error) {
      setResetEmailStatus({ success: false, message: `Error: ${error.message}` });
    }
  };

  const handleRecalculateMileage = async (userId, batch = false) => {
    try {
      // Query the mileage sub-collection for the user
      const mileageQuery = query(collection(firestore, "records", userId, "mileage"));
      const mileageSnapshot = await getDocs(mileageQuery);
      let totalMileage = 0;

      // Calculate total mileage
      mileageSnapshot.forEach((doc) => {
        const { odometerStart, odometerEnd } = doc.data();
        totalMileage += odometerEnd - odometerStart;
      });

      const roundedMileage = Math.round(totalMileage);

      const userDocRef = doc(firestore, "records", userId);
      await updateDoc(userDocRef, { totalMileage: roundedMileage });

      setRecalculatedMileage(roundedMileage);
      if (batch === false) {
        setDialogOpen(true);
        fetchDocuments();
      }
    } catch (error) {
      console.error("Error recalculating mileage:", error);
    }
  };

  const handleDeduplicateRecords = async (userId, batch = false) => {
    try {
      const recordsRef = collection(firestore, "records", userId, "mileage");
      const querySnapshot = await getDocs(recordsRef);
      const seen = new Set();
      const duplicates = [];
      let duplicateCount = 0;

      querySnapshot.forEach((docSnap) => {
        const { vehicleNumber, odometerStart, odometerEnd } = docSnap.data();
        const recordKey = `${vehicleNumber}-${odometerStart}-${odometerEnd}`;
        if (seen.has(recordKey)) {
          duplicates.push(docSnap.id);
          duplicateCount++;
        } else {
          seen.add(recordKey);
        }
      });

      await Promise.all(duplicates.map(async (docId) => {
        const docRef = doc(firestore, "records", userId, "mileage", docId);
        await deleteDoc(docRef);
      }));

      setRemovedDuplicatesCount(duplicateCount); 
      if (batch === false) {
        setDialogOpen(true);
        fetchDocuments();
      }
    } catch (error) {
      console.error("Error deduplicating records:", error);
    }
  };

  const handleRecalculateAllMileage = async () => {
    if (isRecalculating) {
      setProgressOpen(true);
      return;
    }
    isCancelledRef.current = false;

    setIsRecalculating(true);
    setProgressOpen(true);
    setIsCompleted(false);
    try {
      const querySnapshot = await getDocs(collection(firestore, "records"));
      const userIds = querySnapshot.docs.map(doc => doc.id);
      const userNames = await Promise.all(querySnapshot.docs.map(async (document) => {
        const userRef = doc(firestore, "users", document.id);
        const userSnap = await getDoc(userRef);
        const data = {};
        if (userSnap.exists()) {
          const userData = userSnap.data();
          data.userName = userData.name || 'N/A';
        } else {
          data.userName = 'N/A';
        }
        return data;
      }));      
      for (let i = 0; i < userIds.length; i++) {
        if (isCancelledRef.current) break;
        await handleRecalculateMileage(userIds[i], true);
        setCurrentUserName(userNames[i].userName);
        setProgress(Math.round(((i + 1) / userIds.length) * 100));
      }
      setIsCompleted(true);
    } catch (error) {
      console.error("Error recalculating all mileage:", error);
    } finally {
      setIsRecalculating(false);
      isCancelledRef.current = false;
      fetchDocuments();
      setProgress(0);
      setCurrentUserName("");
    }
  };

  // Batch Deduplication of Records
  const handleDeduplicateAllRecords = async () => {
    if (isDeduplicating) {
      setProgressOpen(true);
      return;
    }

    isCancelledRef.current = false;
    setIsDeduplicating(true);
    setProgressOpen(true);
    setIsCompleted(false);
    try {
      const querySnapshot = await getDocs(collection(firestore, "records"));
      console.log(querySnapshot.docs);
      const userIds = querySnapshot.docs.map(doc => doc.id);
      const userNames = await Promise.all(querySnapshot.docs.map(async (document) => {
        const userRef = doc(firestore, "users", document.id);
        const userSnap = await getDoc(userRef);
        const data = {};
        if (userSnap.exists()) {
          const userData = userSnap.data();
          data.userName = userData.name || 'N/A';
        } else {
          data.userName = 'N/A';
        }
        return data;
      })); 
      for (let i = 0; i < userIds.length; i++) {
        if (isCancelledRef.current) break;
        await handleDeduplicateRecords(userIds[i], true);
        setCurrentUserName(userNames[i].userName);
        setProgress(Math.round(((i + 1) / userIds.length) * 100));
      }
      setIsCompleted(true);
    } catch (error) {
      console.error("Error deduplicating all records:", error);
    } finally {
      setIsDeduplicating(false);
      isCancelledRef.current = false;
      fetchDocuments();
      setProgress(0);
      setCurrentUserName("");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setResetEmailStatus(null);
    setRecalculatedMileage(null);
    setRemovedDuplicatesCount(null);
    setProgressOpen(false);
    setCurrentUserName("");
  };

  // Handler for "Edit" button click (for other collections)
  const handleEditClick = (id) => {
    if (collectionName === 'vehicles') {
      navigate(`/vehicles/${id}`);
    } else if (collectionName === 'records') {
      navigate(`/records/${id}`);
    } else {
      navigate(`/${collectionName}/${id}`);
    }
  };

  const columns = collectionName === 'users' ? [
    { field: 'rank', headerName: 'Rank', width: 75 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'unit', headerName: 'Unit', width: 105 },
    { field: 'permitNumber', headerName: 'Permit Number', width: 175 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'frame', headerName: 'Frame', width: 75 },
    { field: 'contact', headerName: 'Contact', width: 100 },
    { field: 'userType', headerName: 'User Type', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      alignItems: 'center',
      display: 'flex',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" color="primary" onClick={() => handleFixAccountClick(params.row.id)}>
            Fix Account
          </Button>
          {params.row.email && (
            <Button variant="contained" color="secondary" onClick={() => handleResetPassword(params.row.email)}>
              Reset Password
            </Button>
          )}
        </Box>
      ),
    },
  ] : collectionName === 'vehicles' ? [
    { field: 'vehMid', headerName: 'Vehicle MID', width: 120 },
    { field: 'vehPlatform', headerName: 'Vehicle Platform', width: 150 },
    { field: 'odometerStart', headerName: 'Odometer Start', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      alignItems: 'center',
      display: 'flex',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" color="primary" onClick={() => handleEditClick(params.row.id)}>
            Edit
          </Button>
        </Box>
      ),
    }
  ] : [
  { field: 'userRank', headerName: 'Rank', width: 75 },
  { field: 'userName', headerName: 'Name', width: 175 },
  { field: 'totalMileage', headerName: 'Total Mileage', width: 115 },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 580,
    alignItems: 'center',
    display: 'flex',
    renderCell: (params) => (
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditClick(params.row.id)}
        >
          Edit
        </Button>
        {collectionName === 'records' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleRecalculateMileage(params.row.id)}
          >
            Auto-Recalculate Mileage
          </Button>
        )}
        {collectionName === 'records' && (
          <Button
            variant="contained"
            color="success"
            onClick={() => handleDeduplicateRecords(params.row.id)}
          >
            De-Duplicate Records
          </Button>
        )}
      </Box>
    ),
  }];

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {collectionName.charAt(0).toUpperCase() + collectionName.slice(1)}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={isMobile ? 1 : 2} alignItems="center">
          {/* Search bar */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                maxWidth: isMobile ? '100%' : '300px',
                margin: 0, 
              }}
            />
          </Grid>

          {/* Buttons */}
          {collectionName === "records" && (
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              container
              justifyContent={isMobile ? "flex-start" : "flex-end"} // Right align on desktop, left align on mobile
              spacing={isMobile ? 1 : 2} // Adjust spacing for mobile
              direction={isMobile ? 'column' : 'row'} // Stack on mobile, inline on desktop
            >
              <Grid item xs={12} sm="auto">
                <Button
                  fullWidth={isMobile} // Full width on mobile
                  variant="contained"
                  color="primary"
                  onClick={handleRecalculateAllMileage}
                  sx={{ fontSize: isMobile ? '0.75rem' : '1rem' }} // Adjust font size
                >
                  Auto-recalculate all mileage
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button
                  fullWidth={isMobile} // Full width on mobile
                  variant="contained"
                  color="secondary"
                  onClick={handleDeduplicateAllRecords}
                  sx={{ fontSize: isMobile ? '0.75rem' : '1rem' }} // Adjust font size
                >
                  Auto-deduplicate all records
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box sx={{ height: 450, width: '100%', marginTop: 2 }}>
        <DataGrid
          rows={documents}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={loading}
          getRowId={(row) => row.id}
          display="flex"
          alignItems="center"
        />
      </Box>

      {/* Fix Account Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Account Update</DialogTitle>
        <DialogContent>
          {recalculatedMileage !== null ? (
            <Typography>
              Total mileage auto-recalculated to {recalculatedMileage} km.
            </Typography>
          ) : removedDuplicatesCount !== null ? (
            <Typography>
              {removedDuplicatesCount} duplicated records removed.
            </Typography>
          ) : addedFields.length > 0 ? (
            <Typography>
              Account fixed. The following fields were added: {addedFields.join(', ')}
            </Typography>
          ) : (
            <Typography>No fields were added, as all fields already existed.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reset Password Status Dialog */}
      <Dialog open={Boolean(resetEmailStatus)} onClose={handleCloseDialog}>
        <DialogTitle>Password Reset</DialogTitle>
        <DialogContent>
          <Typography>
            {resetEmailStatus?.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ProgressDialog 
        open={progressOpen} 
        progress={progress} 
        userName={currentUserName} 
        isCompleted={isCompleted}
        handleClose={handleCloseDialog} 
        handleCancel={handleCancelOperation} 
      />
    </Box>
  );
}