// src/components/HomePage.js
import { Typography, Box } from '@mui/material';

export default function HomePage() {
  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h4" sx={{ marginBottom: '16px', textAlign: 'center' }}>
        Welcome to the Wallaby Tracker Editor!
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '8px' }}>
        - Use the sidebars on the side to view and edit data.
      </Typography>
      <Typography variant="body1">
        - Before deleting data, make sure to edit the mileage to zero first to avoid complications.
      </Typography>
    </Box>
  );
}